import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This is a test page. Here is some code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` foo `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
foo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` f`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`That was cool`}</p>
    <h3>{`very cool`}</h3>
    <Link to="/" mdxType="Link">go home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      